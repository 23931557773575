<template>
  <div>
    <div class="justify-content-center">
      <p class="font-size-h2 font-weight-bold mb-5">Giriş Yap</p>
      <b-form
        class="form fv-plugins-bootstrap fv-plugins-framework"
        @submit.stop.prevent="onSubmit"
      >
        <div class="text-dark-75 font-size-sm mb-10">
          Sisteme giriş yapabilmek için lütfen aşağıdaki alana sisteme
          kayıtlı<br />
          olan e-posta adresinizi giriniz.
        </div>

        <Input
          v-model="form.email"
          :state="validateState('email')"
          :vError="$v.form.email"
          :disabled="this.readOnly"
          placeholder="adiniz@eposta.com"
        />
        <Input
          v-model="form.password"
          :state="validateState('password')"
          :vError="$v.form.password"
          :disabled="this.readOnly"
          placeholder="Şifreniz"
          type="password"
        />
        <div class="d-flex flex-column">
          
        <b-button
          variant="success"
          class="my-5 font-weight-bolder"
          ref="submitButton"
          type="submit"
          :disabled="this.readOnly"
          @click="onSubmit"
          >Giriş
        </b-button>
        <b-button
        :to="'/kayitol'"
        variant="danger"
        class=" font-weight-bolder mb-5"
        type="submit"
        :disabled="this.readOnly"
        >Kayıt Ol
        </b-button>

        </div>
         
      </b-form>

      <div class="text-dark-75 font-size-sm mb-5">
        Eğer giriş yapmakta zorluk çekiyorsanız<br />aşağıdaki bağlantıdan
        bizlere ulaşabilirsiniz.
      </div>
      <div class="text-center">
        <a
          href="mailto:info@dveb.com.tr"
          class="text-dark text-hover-dark-50 font-weight-bold"
          >BİZE ULAŞIN</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { LOGIN, LOGOUT } from '@/core/services/store/auth.module';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
  name: 'Login',
  mixins: [validationMixin],
  data() {
    return {
      readOnly: false,
      form: {
        email: '',
        password: '',
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      //Kopyalama işleminde boşluk olabiliyor, o yüzden bunu ekledim.
      if (this.form.email) this.form.email = this.form.email.trim();

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.readOnly = true;
      let submitButton = this.$refs['submitButton'];
      this.$spinnerLoader.addSpinnerLoader(submitButton);

      this.$store.dispatch(LOGOUT);

      let _samplekey =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpIjoiNjA5YmRjZjAtM2IyZS00MDlkLWFlMTktMzQzY2I4NjE1NThhIiwidCI6IlN5c3RlbUFkbWluaXN0cmF0b3IiLCJlIjoiaWxldGlzaW1AYnVyYWtrYXNpa2NpLmNvbSIsImYiOiJCdXJhayBLYcWfxLFrY8SxIiwiYyI6IiIsIm8iOiIiLCJuYmYiOjE2ODczNzUwNTUsImV4cCI6MTY4NzYzNDI1NSwiaWF0IjoxNjg3Mzc1MDU1fQ.JMZU_OrAcqXIPuaWXR1SvIsvjOTK0Hs7nsyBVSLhhi4';
      this.$store.dispatch(LOGIN, _samplekey).then(() => {
        let currentRoute = this.$JwtService.getCurrentRoute();
        if (currentRoute) {
          this.$JwtService.destroyCurrentRoute();
          window.location = currentRoute;
        } else {
          this.$router.push({ name: 'anasayfa' });
        }
      });

      this.$router.push({ name: 'anasayfa' });

      //TODO : Api bağlantısı sonrası açılacak.

      // this.login()
      //   .then((res) => {
      //     if (res.success == true && res.error == false) {
      //       this.$store.dispatch(LOGIN, res.data).then(() => {
      //         let currentRoute = this.$JwtService.getCurrentRoute();
      //         if (currentRoute) {
      //           this.$JwtService.destroyCurrentRoute();
      //           window.location = currentRoute;
      //         } else {
      //           this.$router.push({ name: 'anasayfa' });
      //         }
      //       });
      //     } else {
      //       this.$generateNotification(
      //         this,
      //         'error',
      //         'Bir hata oluştu, lütfen daha sonra tekrar deneyin.'
      //       );
      //     }
      //   })
      //   .catch((err) => {
      //     if (err == 'Error') {
      //       this.$generateNotification(
      //         this,
      //         'error',
      //         'Bir hata oluştu, lütfen daha sonra tekrar deneyin.'
      //       );
      //     }
      //   })
      //   .finally(() => {
      //     this.readOnly = false;
      //     this.$spinnerLoader.removeSpinnerLoader(submitButton);
      //   });
    },
    login() {
      return new Promise((resolve, reject) => {
        this.$ApiService
          .post('account/login', {
            Email: this.form.email,
            Password: this.form.password,
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
};
</script>
